<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.download') }}
        </h3>

        <router-link :to="{ name: 'general-downloads' }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col lg="7" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.titlePT')" class="mb-3">
            <b-form-input
              required
              v-model="model.namePT"
              maxlength="22"
              type="text"
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.titleEN')" class="mb-3">
            <b-form-input v-model="model.nameEN" maxlength="22" type="text" />
          </b-input-group>

          <TextArea
            :label="$t('artistsManagement.aboutPT')"
            v-model="model.descriptionPT"
            maxlength="500"
          />

          <TextArea
            :label="$t('artistsManagement.aboutEN')"
            v-model="model.descriptionEN"
            maxlength="500"
          />

          <b-form-group :label="$t('buttons.selectFile')" class="mb-2">
            <input
              :required="!model.filename"
              type="file"
              @change="onFileChange"
            />
          </b-form-group>

          <hr />

          <b-form-group :label="$t('inputs.selectIcon')" class="mb-2">
            <ImageUploader minHeight="30" minWidth="90" v-model="uploadIcon" />
          </b-form-group>

          <b-form-group :label="$t('inputs.selectImage')" class="mb-2">
            <ImageUploader minHeight="300" minWidth="490" v-model="upload" />
          </b-form-group>

          <b-input-group class="mb-3" :prepend="$t('inputs.category')">
            <b-form-select
              required
              v-model="model.categoryId"
              :options="categories"
            />
          </b-input-group>

          <b-input-group class="mb-3" :prepend="$t('inputs.type')">
            <b-form-select required v-model="model.typeId" :options="types" />
          </b-input-group>

          <b-input-group class="mb-3" :prepend="$t('inputs.owner')">
            <b-form-select v-model="model.ownerId" :options="owners" />
          </b-input-group>
        </b-col>

        <b-col lg="4" sm="12" class="mb-3">
          <img
            :src="upload.preview"
            v-if="upload.preview"
            class="preview mb-1"
            style="max-width: 100%"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" sm="12" class="mb-3 text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { v4 } from 'uuid';
import TextArea from '../../../../components/organisms/TextArea.vue';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import GeneralDownload from '../../../../services/GeneralDownload';
import Toast from '../../../../assets/js/toast';

export default {
  name: 'DownloadForm',

  components: {
    TextArea,
    ImageUploader,
  },

  data() {
    const data = {
      loaded: false,
      isEdit: false,

      upload: {
        file: null,
        preview: null,
        blob: null,
      },

      uploadIcon: {
        file: null,
        preview: null,
        blob: null,
      },

      options: {},
      selectedFile: {},

      model: {
        namePT: '',
        nameEN: '',
        descriptionPT: '',
        descriptionEN: '',
        categoryId: '',
        typeId: '',
        ownerId: '',
        thumbnail: '',
        icon: '',
        filename: '',
      },
    };
    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function () {
      this.onLoad();
    },
  },

  methods: {
    async onLoad() {
      this.loading = true;
      await this.onConfigDownloadsForm();

      this.loading = false;
      this.loaded = true;
    },

    async onConfigDownloadsForm() {
      const { id } = this.$route.params;
      this.options = await GeneralDownload.getBaseOptions();

      if (id) {
        this.isEdit = true;
        const obj = await GeneralDownload.getById(id);
        this.model = {
          ...obj,
          categoryId: obj.category.id,
          typeId: obj.type.id,
          ownerId: obj.owner?.id,
        };

        if (obj.thumbnail) this.upload.preview = `${Vue.prototype.VUE_APP_S3_URL}/downloads/${obj.thumbnail}`;
        if (obj.icon) this.uploadIcon.preview = `${Vue.prototype.VUE_APP_S3_URL}/downloads/icon/${obj.icon}`;
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        if (this.upload.file) {
          this.model.thumbnail = await this.uploadFile('image');
        }

        if (this.uploadIcon.file) {
          this.model.icon = await this.uploadFile('icon');
        }

        if (this.selectedFile.name) {
          this.model.filename = await this.uploadFile('file');
        }

        const res = await GeneralDownload[action](this.model, this.model.id);
        if (res.conflict) Toast.warn(this, 'messages.scheduleConflict');
        else Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'general-downloads' });
          this.loading = false;
        }, 1000);
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(error);
        });
        Toast.error(this, error);
        this.loading = false;
      }
    },

    onFileChange(e) {
      const selectedFile = e.target.files[0];
      this.selectedFile = selectedFile;
    },

    getFileExtension(file) {
      const arr = file.split('.');
      return arr[arr.length - 1];
    },

    async uploadFile(type) {
      const fd = new FormData();
      let ext = '';
      if (type === 'file' && this.selectedFile) ext = this.getFileExtension(this.selectedFile.name);

      if (type === 'image' && this.upload.file) ext = this.getFileExtension(this.upload.file.name);

      if (type === 'icon' && this.upload.file) ext = this.getFileExtension(this.uploadIcon.file.name);

      const filename = `${v4()}.${ext}`;
      const signed = await GeneralDownload.generateSigned(type, filename);
      const url = signed.postEndpoint;

      // eslint-disable-next-line guard-for-in
      for (const field in signed.signature) {
        fd.append(field, signed.signature[field]);
      }

      if (type === 'file' && this.selectedFile) fd.append('file', this.selectedFile);

      if (type === 'image' && this.upload.file) fd.append('file', this.upload.blob, this.upload.file.name);

      if (type === 'icon' && this.upload.file) fd.append('file', this.uploadIcon.blob, this.uploadIcon.file.name);

      await GeneralDownload.uploadS3(url, fd);
      return filename;
    },
  },
  computed: {
    owners() {
      return this.options.owners.map((owner) => ({
        value: owner.id,
        text: owner.name,
      }));
    },

    categories() {
      return this.options.categories.map((category) => ({
        value: category.id,
        text: this.$i18n.locale === 'pt' ? category.namePT : category.nameEN,
      }));
    },

    types() {
      return this.options.types.map((type) => ({
        value: type.id,
        text: this.$i18n.locale === 'pt' ? type.namePT : type.nameEN,
      }));
    },
  },
};
</script>

<style>
</style>
