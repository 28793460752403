import Vue from 'vue';

export default {
  getDownloads: async () => Vue.http.get('general/downloads')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`general/downloads/${id}`)
    .then((res) => res.json()),

  getBaseOptions: async () => Vue.http.get('general/downloads/base/options')
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('general/downloads', model)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`general/downloads/${id}`, model)
    .then((res) => res.json()),

  uploadFile: async (model) => Vue.http.post('general/downloads/upload/file', model)
    .then((res) => res.json()),

  generateSigned: async (type, filename) => Vue.http.post(`general/downloads/generateSigned/${type}/${filename}`)
    .then((res) => res.json()),

  uploadS3: async (url, fd) => Vue.http.post(url, fd),

  uploadIcon: async (model) => Vue.http.post('general/downloads/upload/icon', model)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`general/downloads/${id}`)
    .then((res) => res.json()),
};
